import { graphql } from 'gatsby';
import { getSrc, ImageDataLike } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../../components/Layout';
import { BlogArticles } from '../../components/molecules/BlogArticle';
import { Main } from '../../components/molecules/Main/Main';
import { PageSection } from '../../components/molecules/PageSection';
import { PopulatedBlogAside } from '../../components/organisms/PopulatedBlogAside';
import { MainLayout } from '../../components/templates/MainLayout/MainLayout';
import { dateMonth } from '../../utils/date.utils';

interface BlogQuery {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: {
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            featuredimage: ImageDataLike;
            description: string;
            date: string;
            category: string;
            tags: string[];
            lien: string;
          };
        };
      }>;
    };
  };
}

const BlogPage = ({ data }: BlogQuery) => {
  const blogArticles = data.allMarkdownRemark.edges;
  const articles = blogArticles.map((article) => ({
    image: getSrc(article.node.frontmatter.featuredimage) ?? '',
    title: article.node.frontmatter.title,
    date: dateMonth(article.node.frontmatter.date),
    category: article.node.frontmatter.category,
    description: article.node.frontmatter.description,
    link: article.node.fields.slug,
  }));

  return (
    <Layout>
      <Main withVerticalSpacing fillHeight>
        <MainLayout aside={<PopulatedBlogAside />}>
          <PageSection withSection backgroundColor="white" title={articles.title}>
            <BlogArticles blogArticles={articles} limit={8} />
          </PageSection>
        </MainLayout>
      </Main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPageTemplate {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            category
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
