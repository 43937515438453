import { Link } from 'gatsby';
import React from 'react';
import { toExtract } from '../../../utils/string';

import { Content, Description, Image, ImageWrapper, SpanWrapper, Title, Wrapper } from './styled';

export interface BlogArticleProps {
  image?: string;
  title: string;
  date: string;
  category: string;
  description: string;
  link: string;
  readMoreTitle?: string;
}

export const BlogArticle: React.FC<BlogArticleProps> = ({
  image,
  title,
  date,
  category,
  description,
  link,
  readMoreTitle,
}) => {
  return (
    <Wrapper>
      <Link to={link}>
        <ImageWrapper>
          {image && <Image src={image} />}
          {!image && <Image src="https://picsum.photos/400/250" />}
        </ImageWrapper>
      </Link>
      <Link to={link}>
        <Content>
          <Title>{title}</Title>
          <SpanWrapper>
            <span>{date}</span>
            <span>{category}</span>
          </SpanWrapper>
          <Description>{toExtract(description, 100)}</Description>
          {readMoreTitle && <p>{readMoreTitle}</p>}
        </Content>
      </Link>
    </Wrapper>
  );
};
