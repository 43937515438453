import React from 'react';

import { BlogArticle, BlogArticleProps } from './BlogArticle';
import { BlogArticlesWrapper } from './styled';

export interface BlogArticlesProps {
  blogArticles: BlogArticleProps[];
  limit?: number;
}

export const BlogArticles: React.FC<BlogArticlesProps> = ({ blogArticles, limit = 0 }) => {
  return (
    <BlogArticlesWrapper>
      {blogArticles
        .map((blogArticle) => (
          <BlogArticle
            image={blogArticle.image}
            title={blogArticle.title}
            date={blogArticle.date}
            category={blogArticle.category}
            description={blogArticle.description}
            link={blogArticle.link}
            readMoreTitle="Voir plus ->"
          />
        ))
        .slice(0, limit)}
    </BlogArticlesWrapper>
  );
};
